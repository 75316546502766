#sidenav {
  background-color: #000;
  color: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  min-width: 250px;
  width: 250px;
  overflow: hidden;
  margin-right: 0;
  height: calc(100vh - 40px);
  position: sticky;
}

#sidenav.active {
  min-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

#sidenav #menutoggle.active {
  display: block;
  margin-top: -10px;
}

.userinfo {
  border: 1px dashed #fff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.userinfo h2 {
  margin: 0;
}

.userinfo h3 {
  margin: 0;
  font-weight: 300;
}

.avatar {
  background-color: #6a6a6a;
  width: 35px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 20px;
  display: none; /* until upload profile image is available */
}

hr {
  border: 0.5px solid #ffffff61;
}

#sidenav ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

#sidenav li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  display: block;
  border-radius: 20px;
}

#sidenav li a.active {
  background: #6a6a6a;
}

h2 {
  line-height: 1;
}
