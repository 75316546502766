.booking-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 90;
}

.booking-form-container {
  width: 490px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
  padding: 10px 30px;
  border-radius: 20px 0 0 20px;
  z-index: 91;
}

.booking-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-form-close-button {
  height: fit-content;
  aspect-ratio: 1;
  border: 0;
  border-radius: 50%;
}

.booking-button {
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
}

.booking-form-container ul {
  padding: 0;
  list-style: none;
}

.booking-form-container ul li {
  margin-top: 10px;
}

.booking-form-container ul li input[type="text"],
.booking-form-container ul li input[type="number"],
.booking-form-container ul li textarea {
  width: 100%;
  padding: 4px 10px 10px 4px;
  border: 0;
  border-bottom: 1px solid hsl(0, 0%, 80%);
}

.small-text {
  font-size: small;
}

.booking-form-container ul li input[type="datetime-local"] {
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
}

.booking-form-container input:focus-visible,
.booking-form-container:focus-visible,
.booking-form-container textarea:focus-visible {
  outline: none;
}

.vehicle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-vehicle-button {
  border: none;
  background: none;
}

.booking-form-footer {
  display: flex;
  justify-content: flex-end;
}

.select-label {
  font-size: small;
  margin-bottom: 0;
}
