* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  height: 100%;
}

main {
  display: flex;
  min-height: 100vh;
}

#menutoggle {
  border-radius: 50%;
  aspect-ratio: 1;
  border: 0;
}

#menutoggle.menu-active {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.header-left {
  display: flex;
  align-items: center;
}

.notfound {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content,
.header {
  position: relative;
  flex: 1;
}

.header {
  margin-bottom: 5px;
}

.content {
  margin: 29px 37px;
}

#dateContainer {
  position: absolute;
  z-index: 9999;
  top: 55px;
  box-shadow: 1px 1px 10px rgba(1, 1, 1, 0.5);
}

#dateContainer,
.rdrCalendarWrapper {
  border-radius: 20px;
}

#dateContainer.calendar-inactive {
  display: none;
}

#dateContainer.menu-active {
  margin-left: 0px;
}

#dateContainer.menu-inactive {
  margin-left: 100px;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
}

.bookingtable {
  overflow: scroll;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icon-profile {
  display: inline-block;
  vertical-align: middle;
}

button {
  cursor: pointer;
}

#dateSelector,
#newBookingButton {
  padding: 10px 15px;
  border: 0;
  border-radius: 20px;
}

input[type="text"],
input[type="password"] {
  padding: 4px 10px 10px 4px;
  border: 0;
  border-bottom: 1px solid hsl(0, 0%, 80%);
}

input:focus-visible {
  outline: none;
}

.booking-title {
  display: flex;
  align-items: center;
  margin: 0;
}

.booking-title h2,
.booking-title h3 {
  margin: 0;
  line-height: 1;
}

.booking-card-row {
  display: flex;
  justify-content: space-between;
}

.booking-card-row p {
  font-weight: bold;
}

.booking-card-item {
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: 20%;
}

.booking-card-item-icon-container {
  margin: 0 auto;
  border: 1px solid;
  border-radius: 10px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.booking-card-item-icon-container span {
  font-size: 40px;
  padding: 20px;
}

.booking-information-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locations,
.pax,
.price {
  display: flex;
}

.locations p,
.pax p,
.price p {
  flex: 1;
  margin: 0;
}

ul.settings-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

ul.settings-nav li {
  margin-right: 10px;
}

.settings-component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formContainer {
  width: 350px;
  max-width: 100%;
  padding: 20px 30px;
}

.formContainer ul {
  list-style: none;
  padding: 0;
}

.formContainer li {
  margin-bottom: 10px;
}

.formContainer input {
  width: 100%;
}

.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
